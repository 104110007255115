import Axios from '@/utils/axios';
import Api from '@/constant/api';

// 获取log options选项
export const getLogParams = async (params) => {
    return Axios.jsonGet(`${Api.fr.result.log}${params.month_version}/`, { params });
};
// 获取log列表
export const getLogs = async (params) => {
    return Axios.jsonPost(`${Api.fr.resultBySku.log}`, params);
};
// 删除log
export const deleteLog = async (params) => {
    return Axios.jsonGet(`${Api.fr.resultBySku.logDelete}`, { params });
};
// 修改别名
export const changeAlias = async (params) => {
    return Axios.jsonPost(`${Api.fr.resultBySku.changeAlias}`, params);
};

export const getStep3VersionLogById = async (params) => {
    return Axios.jsonGet(`${Api.fr.resultBySku.logInfo}`, { params });
};

export const getStep2versionbrand = async (params) => {
    return Axios.jsonGet(`${Api.fr.resultBySku.getStep2versionbrand}`, { params });
};


