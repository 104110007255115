import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store';
import {
  HomeOutlined,
  AreaChartOutlined,
  PieChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DatabaseOutlined,
  CalculatorOutlined,
  PercentageOutlined,
  DashboardOutlined,
  SnippetsOutlined,
  UserOutlined,
  DeploymentUnitOutlined,
  ProjectOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import s from './index.module';
import { toJS } from 'mobx';

const { Item, SubMenu } = Menu;

const router = {
  home: '/',
  dp_pos: 'dp-pos',
  dp_nielsen: 'dp-nielsen',
  fa_pos: 'fa-pos',
  fa_nielsen: 'fa-nielsen',
  ci_pos: 'ci-pos',
  ci_nielsen: 'ci-nielsen',
  fr_result: 'fr-result',
  fr_output: 'fr-output',
  log: 'log',
  npd: 'npd',
  change_password: 'change-password',
  ex_rfc_forecast: 'ex-rfc-forecast',
  so_pos_comparison: 'so-pos-comparison',
  so_yoy_comparison: 'so-yoy-comparison',
  so_rfc_comparison: 'so-rfc-comparison',
  so_pos_analysis: 'so-pos-analysis',
  so_rfc_analysis: 'so-rfc-analysis',
  result_by_sku: 'result-by-sku',
  auth_manage: 'auth-manage',
  role_manage: 'role-manage',
  account_manage: 'account-manage',
  cbec_data_split: 'cbec-data-split',
};

const Menus = observer((props) => {
  const {
    application,
    application: { menuCollapsed, menuOpens, menuSelects },
  } = useStores();

  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const roles = getRoles();
    if (roles.length) {
      const root = roles.find((item) => item.role_value === 'root');
      setCanEdit(root ? true : false);
    }
  }, [application.user]);

  const getRoles = () => {
    const { user: { roles = [] } = {} } = application;
    return roles;
  };

  const { pathname } = useLocation();

  const toggle = () => {
    application.updateMenuCollapsed(!menuCollapsed);
  };

  useEffect(() => {
    const [open, select] = getDefaultSelectKeys();
    application.updateMenuData(open, select);
  }, [pathname]);

  const getDefaultSelectKeys = () => {
    let open = toJS(menuOpens);
    let select = toJS(menuSelects);
    if (!pathname || pathname === router.home) {
      select = ['sub0'];
    }
    if (pathname.includes(router.dp_pos)) {
      open = [...open, 'sub1a'];
      select = ['sub1a-1'];
    }
    if (pathname.includes(router.dp_nielsen)) {
      open = [...open, 'sub1a'];
      select = ['sub1a-2'];
    }
    if (pathname.includes(router.fa_pos)) {
      open = [...open, 'sub2a'];
      select = ['sub2a-1'];
    }
    if (pathname.includes(router.fa_nielsen)) {
      open = [...open, 'sub2a'];
      select = ['sub2a-2'];
    }
    if (pathname.includes(router.ci_pos)) {
      open = [...open, 'sub3a'];
      select = ['sub3a-1'];
    }
    if (pathname.includes(router.ci_nielsen)) {
      open = [...open, 'sub3a'];
      select = ['sub3a-2'];
    }

    if (pathname.includes(router.fr_result)) {
      open = [...open, 'sub40'];
      select = ['sub4a-0'];
    }
    if (pathname.includes(router.cbec_data_split)) {
      open = [...open, 'sub4a'];
      select = ['sub4a-1'];
    }
    if (pathname.includes(router.fr_output)) {
      open = [...open, 'sub4a'];
      select = ['sub4a-2'];
    }
    if (pathname.includes(router.result_by_sku)) {
      open = [...open, 'sub4a'];
      select = ['sub4a-3'];
    }
    if (pathname.includes(router.ex_rfc_forecast)) {
      open = [...open, 'sub5a'];
      select = ['sub5a-1'];
    }
    if (pathname.includes(router.so_yoy_comparison)) {
      open = [...open, 'sub6a'];
      select = ['sub6a-1'];
    }
    if (pathname.includes(router.so_pos_comparison)) {
      open = [...open, 'sub6a'];
      select = ['sub6a-2'];
    }
    if (pathname.includes(router.so_rfc_comparison)) {
      open = [...open, 'sub6a'];
      select = ['sub6a-3'];
    }
    if (pathname.includes(router.so_pos_analysis)) {
      open = [...open, 'sub6a'];
      select = ['sub6a-4'];
    }
    if (pathname.includes(router.so_rfc_analysis)) {
      open = [...open, 'sub6a'];
      select = ['sub6a-5'];
    }
    if (pathname.includes(router.log)) {
      select = ['sub7a'];
    }
    if (pathname.includes(router.npd)) {
      select = ['subaNPD'];
    }
    if (pathname.includes(router.change_password)) {
      open = [...open, 'sub8a'];
      select = ['sub8a-1'];
    }
    if (pathname.includes(router.auth_manage)) {
      open = [...open, 'sub9a'];
      select = ['sub9a-1'];
    }
    if (pathname.includes(router.account_manage)) {
      open = [...open, 'sub9a'];
      select = ['sub9a-2'];
    }
    if (pathname.includes(router.role_manage)) {
      open = [...open, 'sub9a'];
      select = ['sub9a-3'];
    }
    return [open, select];
  };

  return (
    <div className={s.menu}>
      <Menu
        className={s.top}
        theme="light"
        selectedKeys={menuSelects}
        openKeys={menuOpens}
        onOpenChange={(openKeys) => {
          application.updateMenuData(openKeys);
        }}
        onSelect={({ selectedKeys }) => {
          application.updateMenuData(undefined, selectedKeys);
        }}
        mode="inline"
      >
        <Item key="sub0" icon={<HomeOutlined />}>
          <Link to="/">Front Page</Link>
        </Item>
        <SubMenu
          key="sub1a"
          title={
            <>
              <DatabaseOutlined />
              <span>Data Preparation</span>
            </>
          }
        >
          <Item key="sub1a-1">
            <Link to="/dp-pos">Internal POS</Link>
          </Item>
          <Item key="sub1a-2">
            <Link to="/dp-nielsen">Nielsen data</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub2a"
          title={
            <>
              <CalculatorOutlined />
              <span>Forecast assumptions</span>
            </>
          }
        >
          <Item key="sub2a-1">
            <Link to="/fa-pos">Internal POS assmuptions</Link>
          </Item>
          <Item key="sub2a-2">
            <Link to="/fa-nielsen">Nielsen competitors comparison</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub3a"
          title={
            <>
              <PercentageOutlined />
              <span>Conversion Index</span>
            </>
          }
        >
          <Item key="sub3a-1">
            <Link to="/ci-pos">Internal POS Conversion Index</Link>
          </Item>
          <Item key="sub3a-2">
            <Link to="/ci-nielsen">Nielsen Conversion Index</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub4a"
          title={
            <>
              <DashboardOutlined />
              <span>Forecast Result</span>
            </>
          }
        >
          <Item key="sub4a-0">
            <Link to="/fr-result">Forecast Result by Stage</Link>
          </Item>
          <Item key="sub4a-1">
            <Link to="/cbec-data-split">CBEC Data Split </Link>
          </Item>
          <Item key="sub4a-2">
            <Link to="/fr-output">Result Output</Link>
          </Item>
          <Item key="sub4a-3">
            <Link to="/result-by-sku">POS Result by SKU</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub5a"
          title={
            <>
              {/* <DashboardOutlined /> */}
              <DeploymentUnitOutlined />
              <span>EX RFC Forecast Result</span>
            </>
          }
        >
          <Item key="sub5a-1">
            <Link to="/ex-rfc-forecast">EX RFC Forecast by SKU</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub6a"
          title={
            <>
              <ProjectOutlined />
              <span>Summary & Output</span>
            </>
          }
        >
          <Item key="sub6a-1">
            <Link to="/so-yoy-comparison">POS & EX-RFC YoY comparison</Link>
          </Item>
          <Item key="sub6a-2">
            <Link to="/so-pos-comparison">POS version comparison</Link>
          </Item>
          <Item key="sub6a-3">
            <Link to="/so-rfc-comparison">EX-RFC version comparison</Link>
          </Item>
          <Item key="sub6a-4">
            <Link to="/so-pos-analysis">POS accuracy analysis</Link>
          </Item>
          <Item key="sub6a-5">
            <Link to="/so-rfc-analysis">EX-RFC accuracy analysis</Link>
          </Item>
        </SubMenu>
        <SubMenu
          key="sub8a"
          title={
            <>
              <SnippetsOutlined />
              <span>CBEC Smartpath Analysis</span>
            </>
          }
        >
          <SubMenu
            key="sub8a-1"
            title={
              <>
                <span>Index</span>
              </>
            }
          >
            <Item key="sub8a-1-1">
              <Link to="/IndexBYMS">Index by MS</Link>
            </Item>
            <Item key="sub8a-1-2">
              <Link to="/IndexBYP">Index by P</Link>
            </Item>
            <Item key="sub8a-1-3">
              <Link to="/IndexBYUP">Index by UP</Link>
            </Item>

          </SubMenu>

          <SubMenu
            key="sub8a-2"
            title={
              <>
                <span>Growth</span>
              </>
            }
          >
            <Item key="sub8a-2-1">
              <Link to="/GrowUpBySegment">Growth by Segment</Link>
            </Item>
            <Item key="sub8a-2-2">
              <Link to="/GrowUpByMS">Growth by MS</Link>
            </Item>
            <Item key="sub8a-2-3">
              <Link to="/GrowUpByP">Growth by P</Link>
            </Item>
            <Item key="sub8a-2-4">
              <Link to="/GrowUpByUP">Growth by UP</Link>
            </Item>

          </SubMenu>

          <SubMenu
            key="sub8a-3"
            title={
              <>
                <span>Market Share</span>
              </>
            }
          >
            <Item key="sub8a-3-1">
              <Link to="/SHAREINUPBYSegment">Market Share by Segment</Link>
            </Item>
            <Item key="sub8a-3-4">
              <Link to="/SHAREINUPBYMS">Market Share by MS</Link>
            </Item>
            <Item key="sub8a-3-5">
              <Link to="/SHAREINUPBYP">Market Share by P</Link>
            </Item>
            <Item key="sub8a-3-6">
              <Link to="/SHAREINUPBYUP">Market Share by UP</Link>
            </Item>
            <Item key="sub8a-3-3">
              <Link to="/SHAREINUPBYSChannel">Market Share by Channel</Link>
            </Item>
            <Item key="sub8a-3-2">
              <Link to="/SHAREINUPBYSubChannel">Market Share by SubChannel</Link>
            </Item>


          </SubMenu>
          <Item key="sub8a-4">
            <Link to="/Rank">Rank</Link>
          </Item>

        </SubMenu>
        <Item key="subaPOSLED" icon={<PieChartOutlined />}>
          <Link to="/POSLEReport">POS LE Report</Link>
        </Item>




        <Item key="subaNPD" icon={<AreaChartOutlined />}>
          <Link to="/NpdPage">NPD</Link>
        </Item>
        {/* <Item key="sub7a" icon={<SnippetsOutlined />}>
        </Item> */}
        {/* <Item key="sub7a">

          <Link to="/log">Log</Link>
        </Item> */}
        {/* <Item key="subaNPD">
          <Link to="/npd">NPD</Link>
        </Item> */}
        {/* <SubMenu
          key="sub8a"
          title={
            <>
              <UserOutlined />
              <span>User Center</span>
            </>
          }
        >
          <Item key="sub8a-1">
            <Link to="/change-password">Change Password</Link>
          </Item>
        </SubMenu> */}

        {canEdit ? (
          <SubMenu
            key="sub9a"
            title={
              <>
                <SettingOutlined />
                <span>系统管理</span>
              </>
            }
          >
            <Item key="sub9a-1">
              <Link to="/auth-manage">权限管理</Link>
            </Item>
            <Item key="sub9a-2">
              <Link to="/account-manage">用户管理</Link>
            </Item>
            <Item key="sub9a-3">
              <Link to="/role-manage">角色管理</Link>
            </Item>
          </SubMenu>
        ) : (
          false
        )}

        <Menu mode="inline" theme="light" selectable={false}>
          <Menu.Divider />
          <Menu.Item key="1" icon={menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={toggle} />
        </Menu>

      </Menu>


    </div>
  );
});

export default Menus;
