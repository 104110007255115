const prefix = '/DF/api';
const smartCube = '/smart_cube/api';

const Api = {
  user: {
    login: `${smartCube}/account/user/login`,
    info: `${smartCube}/account/user/info`,
    logout: `${smartCube}/account/user/logout`,
    password: `${prefix}/users/password/`,
  },
  home: {
    overview: `${prefix}/admin/data/overview`,
    top_sales: `${prefix}/admin/data/top_sales`,
    top_orders: `${prefix}/admin/data/top_orders`,
  },
  common: {
    pos_process: `${prefix}/process/pos/`,
    nielsen_process: `${prefix}/process/nielsen/`,
    months: `${prefix}/months/`,
    upload: `${prefix}/files/upload/`,
    downloadTask: `${prefix}/files/download-task/`,
  },
  dp: {
    pos: {
      config: `${prefix}/pos-config/`,
      update: `${prefix}/pos-update/`,
      update_data: `${smartCube}/sku_summary/cbec/update_data`,
    },
    nielsen: {
      config: `${prefix}/nielsen-config/`,
      update: `${prefix}/nielsen-update/`,
    },
  },
  fa: {
    pos: {
      setting: `${prefix}/pos-assumption/setting/`,

      detail: `${prefix}/pos-assumption/detail/`,
      adjust: `${prefix}/pos-assumption/adjust/setting/`, // 设定POS调整预测值参数(仅线性+时间序列)
      adjustManual: `${prefix}/pos-assumption/adjust/manual/`,
      log: `${prefix}/pos-assumption/query-log/`,
      logDelete: `${prefix}/pos-assumption/log/`,
      saveAs: `${prefix}/pos-assumption/log/`,
      save: `${prefix}/pos-assumption/log/`,
      check: `${prefix}/pos-assumption/check/`,
      input: `${prefix}/pos-assumption/input/`,
      rollback: `${prefix}/pos-assumption/rollback/`,
      erase: `${prefix}/pos-assumption/adjust/erase/`,
      changeAlias: `${prefix}/pos-assumption/change-alias/`,
      download: `${prefix}/files/download-other-method-template/`,
      slopeSetting: `${prefix}/pos-assumption/ref-slope-setting/`,
      aggSetting: `${prefix}/agg-setting/`,
      logSave: `${prefix}/pos-assumption/save-log/`,
      logDeleteStepVersion1: `${smartCube}/sku_summary/pos/deleteStepVersion1`,
      stagebrand: `${smartCube}/sku_summary/pos/stage_brand/`,

    },
    nielsen: {
      setting: `${prefix}/nielsen-assumption/setting/`,
      detail: `${prefix}/nielsen-assumption/detail/`,
    },
  },
  ci: {
    pos: {
      setting: `${prefix}/pos-conversion/setting/`,
      detail: `${prefix}/pos-conversion/detail/`,
    },
    nielsen: {
      setting: `${prefix}/nielsen-conversion/setting/`,
      detail: `${prefix}/nielsen-conversion/detail/`,
    },
  },
  fr: {
    result: {
      setting: `${prefix}/result-contrast/setting/`,
      detail: `${prefix}/result-contrast/detail/`,
      rightSetting: `${prefix}/result/setting/`,
      rightDetail: `${prefix}/result/detail/`,
      modifyQuarterly: `${prefix}/result/modify/quarterly-index/`,
      modifyMonthly: `${prefix}/result/modify/monthly-ratio/`,
      modifyMonthlyTemp: `${prefix}/result/modify/monthly-ratio-temp/`,
      log: `${prefix}/result/query-log/`,
      logDelete: `${prefix}/result/detail/`,
      check: `${prefix}/result/check/`,
      input: `${prefix}/result/input/`,
      rollback: `${prefix}/result/rollback/`,
      saveAs: `${prefix}/result/detail/`,
      changeAlias: `${prefix}/result/change-alias/`,
      erase: `${prefix}/result/erase/quarterly-index/`,
      eraseAdjust: `${prefix}/result/erase/monthly-ratio/`,
      logSave: `${prefix}/result/save-log/`,
    },
    output: {
      setting: `${prefix}/output/setting/`,
      download: `${prefix}/files/download-result/`,
      slopeSetting: `${prefix}/output/ref-slope-setting/`,
      slopeDetail: `${prefix}/output/ref-slope-detail/`,
    },
    // 二期 新增页面-POS result by SKU
    resultBySku: {
      list: `${smartCube}/sku_summary/pos/sku_info`,
      setting: `${smartCube}/sku_summary/pos/settings`,
      calc: `${smartCube}/sku_summary/pos/pos_sku_calc`,
      calc_status: `${smartCube}/sku_summary/pos/pos_sku_calc_status`,
      change_percent: `${smartCube}/sku_summary/pos/change_percent`,
      confirm_change: `${smartCube}/sku_summary/pos/confirm_change`,
      reset_change: `${smartCube}/sku_summary/pos/reset_change`,
      save_as: `${smartCube}/sku_summary/pos/save_as`,
      log: `${smartCube}/sku_summary/pos/getStep3VersionLogByCon`,
      logDelete: `${smartCube}/sku_summary/pos/deleteStepVersion3`,
      changeAlias: `${smartCube}/sku_summary/pos/modifyStep3VersionLogById`,
      logInfo: `${smartCube}/sku_summary/pos/getStep3VersionLogById`,
      getStep2versionbrand: `${smartCube}/sku_summary/pos/step2_version_brand`,
      logDeleteStepVersion2: `${smartCube}/sku_summary/pos/deleteStepVersion2`,
    },
    // 三期 新增页面-CBEC Data split
    cbec: {
      setting: `${smartCube}/sku_summary/cbec/settings`,
      list: `${smartCube}/sku_summary/cbec_v2/split_data_tables`,
      update: `${smartCube}/sku_summary/cbec/update_cbec_data`,
      changeAllPercent: `${smartCube}/sku_summary/cbec/change_all_percent`,
      adjust: `${smartCube}/sku_summary/cbec/change_data`,
      markDataSplit: `${smartCube}/sku_summary/cbec/mark_data_split`,
      changeTableData: `${smartCube}/sku_summary/cbec_v2/change_table_data`,
      updateV2: `${smartCube}/sku_summary/cbec_v2/update_cbec_data`,
      cbecstep2versionbrand: `${smartCube}/sku_summary/cbec_v2/cbec_step2_version_brand`,
    },
  },
  log: {
    log: `${prefix}/query-log/`,
  },
  exRfc: {
    forecastBySku: {
      setting: `${smartCube}/sku_summary/ex_rfc/settings`,
      list: `${smartCube}/sku_summary/ex_rfc/forecast_info`,
      confirmlist: `${smartCube}/sku_summary/ex_rfc/confirm_forecast_info`,
      saveResult: `${smartCube}/sku_summary/ex_rfc/save_result`,
      focExportCommit: `${smartCube}/sku_summary/ex_rfc/foc_export_commit`,
      exportStatus: `${smartCube}/sku_summary/file/export_status`,
      focUploadConfirm: `${smartCube}/sku_summary/ex_rfc/foc_upload_confirm`,
      focUploadConfirmStatus: `${smartCube}/sku_summary/ex_rfc/foc_upload_confirm_status`,
      changeDio: `${smartCube}/sku_summary/ex_rfc/change_dio`,
      markFinal: `${smartCube}/sku_summary/ex_rfc/mark_final`,
      saveAs: `${smartCube}/sku_summary/ex_rfc/save_as`,
      changeVersionDio: `${smartCube}/sku_summary/ex_rfc/change_version_dio`,
      delVersion: `${smartCube}/sku_summary/ex_rfc/del_version`,
      getVersionInfo: `${smartCube}/sku_summary/ex_rfc/version_info`,
      confirmFinalVersion: `${smartCube}/sku_summary/ex_rfc/confirm_final`,
      step3versionbrand: `${smartCube}/sku_summary/ex_rfc/step3_version_brand`,
      step3versionid: `${smartCube}/sku_summary/ex_rfc/step3_version_id`,
      getStep4VersionLogByCon: `${smartCube}/sku_summary/ex_rfc/getStep4VersionLogByCon`,
      modifyStep4VersionLogById: `${smartCube}/sku_summary/ex_rfc/modifyStep4VersionLogById`,
      getStep4VersionLogById: `${smartCube}/sku_summary/ex_rfc/getStep4VersionLogById`,
      deleteStepVersion4: `${smartCube}/sku_summary/ex_rfc/deleteStepVersion4`,
    },
    download: {
      focDownload: `${smartCube}/sku_summary/ex_rfc/foc_download`,
    },
    upload: {
      focUpload: `${smartCube}/sku_summary/file/upload`,
    },
  },
  sysManage: {
    auth: {
      createAuth: `${smartCube}/account/auth/alter_auth`,
      editAuth: `${smartCube}/account/auth/alter_auth`,
      delAuth: `${smartCube}/account/auth/del_auth`,
      authList: `${smartCube}/account/auth/auth_list`,
      authsGroup: `${smartCube}/account/auth/auth_group`,
      authGroupList: `${smartCube}/account/auth/auth_group_list`,
      authInfo: `${smartCube}/account/auth/auth_info`,
      authGroupInfo: `${smartCube}/account/auth/auth_group_info`,
      createAuthGroup: `${smartCube}/account/auth/auth_group_create`,
      editAuthGroup: `${smartCube}/account/auth/auth_group_edit`,
      authGroupSimple: `${smartCube}/account/auth/auth_group_simple`,
      getCityList: `${smartCube}/fmc/config/city_list`,
    },
    role: {
      createRole: `${smartCube}/account/role/alter_role`,
      editRole: `${smartCube}/account/role/alter_role`,
      delRole: `${smartCube}/account/role/del_role`,
      roleList: `${smartCube}/account/role/role_list`,
      roleInfo: `${smartCube}/account/role/role_info`,
    },
    account: {
      accountInfo: `${smartCube}/account/user/user_detail`,
      accountList: `${smartCube}/account/user/list`,
      accountAlter: `${smartCube}/account/user/alter_user`,
      modifyPass: `${smartCube}/account/user/modify_password`,
      delAccount: `${smartCube}/account/user/del_account`,
      getAccountRoleList: `${smartCube}/account/role/simple_role_list`,
    },
  },
  summary: {
    soYoy: {
      setting: `${smartCube}/sku_summary/summary/settings`,
      list: `${smartCube}/sku_summary/summary/yoy_compare_table`,
      simpleExportCommit: `${smartCube}/sku_summary/summary/simple_export_commit`,
      completeExportCommit: `${smartCube}/sku_summary/summary/complete_export_commit`,
      exportStatus: `${smartCube}/sku_summary/file/export_status`,
    },
    soPos: {
      setting: `${smartCube}/sku_summary/summary/settings`,
      list: `${smartCube}/sku_summary/summary/pos_version_compare_table`,
      listyear: `${smartCube}/sku_summary/summary/pos_version_compare_table_year`,
    },
    soRfc: {
      list: `${smartCube}/sku_summary/summary/ex_rfc_version_compare_table`,
    },
    posAnalysis: {
      setting: `${smartCube}/sku_summary/summary/analysis_settings`,
      list: `${smartCube}/sku_summary/summary/pos_analysis_info`,
    },
    rfcAnalysis: {
      list: `${smartCube}/sku_summary/summary/ex_rfc_analysis_info`,
    },
    download: `${smartCube}/sku_summary/summary/file_download`,
    subbrandssettings: `${smartCube}/sku_summary/summary/subbrands_settings`,
    getConditionByView: `${smartCube}/sku_summary/cbec/getConditionByView`,
    getToken: `${smartCube}/sku_summary/cbec/getToken`,

    upload: {},
  },
};

export default Api;
