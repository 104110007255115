import React from 'react';
// import Loading from './loading';
import Auth from './auth';
import { Outlet } from 'react-router-dom';
import Root from '@/components/Root';
import Loading from '@/components/Loading';

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ '@/containers/Home'));
const NotFound = React.lazy(() => import(/* webpackChunkName: "not-found" */ '@/containers/NotFound'));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ '@/containers/Login'));
const DataPreparationPos = React.lazy(() => import(/* webpackChunkName: "DataPreparationPos" */ '@/containers/DataPreparation/Pos'));
const LoginForScanCode = React.lazy(() => import(/* webpackChunkName: "loginForScanCode" */ '@/containers/LoginForScanCode'));
const LoginForError = React.lazy(() => import(/* webpackChunkName: "LoginForError" */ '@/containers/LoginForError'));

const DataPreparationNielsen = React.lazy(() =>
  import(/* webpackChunkName: "DataPreparationNielsen" */ '@/containers/DataPreparation/Nielsen')
);
const FctAssumptionsPos = React.lazy(() => import(/* webpackChunkName: "FctAssumptionsPos" */ '@/containers/FctAssumptions/Pos'));
const FctAssumptionsNielsen = React.lazy(() =>
  import(/* webpackChunkName: "FctAssumptionsComparison" */ '@/containers/FctAssumptions/Nielsen')
);

const ConversionIndexPos = React.lazy(() => import(/* webpackChunkName: "FctAssumptionsPos" */ '@/containers/ConversionIndex/Pos'));
const ConversionIndexNielsen = React.lazy(() =>
  import(/* webpackChunkName: "ConversionIndexNielsen" */ '@/containers/ConversionIndex/Nielsen')
);

const ForecastResult = React.lazy(() => import(/* webpackChunkName: "FctAssumptionsPos" */ '@/containers/ForecastResult/Result'));
const ForecastResultOutput = React.lazy(() => import(/* webpackChunkName: "ForecastResultOutput" */ '@/containers/ForecastResult/Output'));
const ResultBySku = React.lazy(() => import(/* webpackChunkName: "ResultBySku" */ '@/containers/ForecastResult/ResultBySku'));
const CbecDataSplit = React.lazy(() => import(/* webpackChunkName: "CbecDataSplit" */ '@/containers/ForecastResult/CbecDataSplit'));

const LogPage = React.lazy(() => import(/* webpackChunkName: "LogPage" */ '@/containers/Log'));
const POSLEReportPage = React.lazy(() => import(/* webpackChunkName: "POSLEReportPage" */ '@/containers/POSLEReportPage'));
const ChangePassword = React.lazy(() => import(/* webpackChunkName: "ChangePassword" */ '@/containers/ChangePassword'));
const YoYComparison = React.lazy(() => import(/* webpackChunkName: "SoYoyComparison" */ '@/containers/Summary/YoYComparison'));
const PosComparison = React.lazy(() => import(/* webpackChunkName: "PosComparison" */ '@/containers/Summary/PosComparison'));
const RfcComparison = React.lazy(() => import(/* webpackChunkName: "RfcComparison" */ '@/containers/Summary/RfcComparison'));
const PosAnalysis = React.lazy(() => import(/* webpackChunkName: "PosAnalysis" */ '@/containers/Summary/PosAnalysis'));
const RfcAnalysis = React.lazy(() => import(/* webpackChunkName: "RfcAnalysis" */ '@/containers/Summary/RfcAnalysis'));

const ExRfcForecastResultBySku = React.lazy(() =>
  import(/* webpackChunkName: "ExRfcForecastResultBySku" */ '@/containers/ExRfcForecastResult/BySku')
);

// 系统管理
const AccountManage = React.lazy(() => import(/* webpackChunkName: "AccountManage" */ '@/containers/SysManage/AccountManage'));
const AccountAlter = React.lazy(() => import(/* webpackChunkName: "AuthManage" */ '@/containers/SysManage/AccountManage/AccountAlter'));
const AuthManage = React.lazy(() => import(/* webpackChunkName: "AuthManage" */ '@/containers/SysManage/AuthManage'));
const AuthAlter = React.lazy(() => import(/* webpackChunkName: "AuthManage" */ '@/containers/SysManage/AuthManage/AuthAlter'));
const RoleManage = React.lazy(() => import(/* webpackChunkName: "RoleManage" */ '@/containers/SysManage/RoleManage'));
const RoleAlter = React.lazy(() => import(/* webpackChunkName: "AuthManage" */ '@/containers/SysManage/RoleManage/RoleAlter'));

const IndexBYMS = React.lazy(() => import(/* webpackChunkName: "IndexBYMS" */ '@/containers/NielsenDataAnalysis/Index/IndexBYMS'));
const IndexBYP = React.lazy(() => import(/* webpackChunkName: "IndexBYP" */ '@/containers/NielsenDataAnalysis/Index/IndexBYP'));
const IndexBYUP = React.lazy(() => import(/* webpackChunkName: "IndexBYUP" */ '@/containers/NielsenDataAnalysis/Index/IndexBYUP'));

const GrowUpBySegment = React.lazy(() => import(/* webpackChunkName: "GrowUpBySegment" */ '@/containers/NielsenDataAnalysis/GrowsUp/GrowUpBySegment'));
const GrowUpByMS = React.lazy(() => import(/* webpackChunkName: "GrowUpByMS" */ '@/containers/NielsenDataAnalysis/GrowsUp/GrowUpByMS'));
const GrowUpByP = React.lazy(() => import(/* webpackChunkName: "GrowUpByP" */ '@/containers/NielsenDataAnalysis/GrowsUp/GrowUpByP'));
const GrowUpByUP = React.lazy(() => import(/* webpackChunkName: "GrowUpByUP" */ '@/containers/NielsenDataAnalysis/GrowsUp/GrowUpByUP'));

const SHAREINUPBYSegment = React.lazy(() => import(/* webpackChunkName: "GrowUpBySegment" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYSegment'));
const SHAREINUPBYSubChannel = React.lazy(() => import(/* webpackChunkName: "SHAREINUPBYSubChannel" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYSubChannel'));
const SHAREINUPBYSChannel = React.lazy(() => import(/* webpackChunkName: "SHAREINUPBYSChannel" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYSChannel'));
const SHAREINUPBYMS = React.lazy(() => import(/* webpackChunkName: "SHAREINUPBYMS" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYMS'));
const SHAREINUPBYP = React.lazy(() => import(/* webpackChunkName: "SHAREINUPBYP" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYP'));
const SHAREINUPBYUP = React.lazy(() => import(/* webpackChunkName: "SHAREINUPBYUP" */ '@/containers/NielsenDataAnalysis/SHAREINUP/SHAREINUPBYUP'));
const Rank = React.lazy(() => import(/* webpackChunkName: "Rank" */ '@/containers/NielsenDataAnalysis/Rank'));

const NpdPage = React.lazy(() => import(/* webpackChunkName: "NpdPage" */ '@/containers/NpdPage'));



function Page(Component, useAuth) {
  return (
    <React.Suspense fallback={<Loading />}>
      <Auth useAuth={useAuth}>
        <Component />
      </Auth>
    </React.Suspense>
  );
}

const routes = [
  {
    path: '/',
    element: (
      <Root>
        <Outlet />
      </Root>
    ),
    children: [
      {
        path: '/',
        element: Page(Home, true),
      },
      {
        path: '/dp-pos',
        element: Page(DataPreparationPos, true),
      },
      {
        path: '/dp-nielsen',
        element: Page(DataPreparationNielsen, true),
      },
      {
        path: '/fa-pos',
        element: Page(FctAssumptionsPos, true),
      },
      {
        path: '/fa-nielsen',
        element: Page(FctAssumptionsNielsen, true),
      },
      {
        path: '/ci-pos',
        element: Page(ConversionIndexPos, true),
      },
      {
        path: '/ci-nielsen',
        element: Page(ConversionIndexNielsen, true),
      },
      {
        path: '/fr-result',
        element: Page(ForecastResult, true),
      },
      {
        path: '/fr-output',
        element: Page(ForecastResultOutput, true),
      },
      {
        path: '/result-by-sku',
        element: Page(ResultBySku, true),
      },
      {
        path: '/cbec-data-split',
        element: Page(CbecDataSplit, true),
      },
      {
        path: '/log',
        element: Page(LogPage, true),
      },
      {
        path: '/POSLEReport',
        element: Page(POSLEReportPage, true),
      },
      {
        path: '/change-password',
        element: Page(ChangePassword, true),
      },
      {
        path: '/so-yoy-comparison',
        element: Page(YoYComparison, true),
      },
      {
        path: '/so-pos-comparison',
        element: Page(PosComparison, true),
      },
      {
        path: '/so-rfc-comparison',
        element: Page(RfcComparison, true),
      },
      {
        path: '/so-pos-analysis',
        element: Page(PosAnalysis, true),
      },
      {
        path: '/so-rfc-analysis',
        element: Page(RfcAnalysis, true),
      },
      {
        path: '/ex-rfc-forecast',
        element: Page(ExRfcForecastResultBySku, true),
      },
      {
        path: '/account-manage',
        element: Page(AccountManage, true),
      },
      {
        path: '/account-create',
        element: Page(AccountAlter, true),
      },
      {
        path: '/auth-manage',
        element: Page(AuthManage, true),
      },
      {
        path: '/auth-create',
        element: Page(AuthAlter, true),
      },
      {
        path: '/role-manage',
        element: Page(RoleManage, true),
      },
      {
        path: '/role-create',
        element: Page(RoleAlter, true),
      },
      {
        path: '/IndexBYMS',
        element: Page(IndexBYMS, true),
      },
      {
        path: '/IndexBYP',
        element: Page(IndexBYP, true),
      },
      {
        path: '/IndexBYUP',
        element: Page(IndexBYUP, true),
      },
      {
        path: '/GrowUpBySegment',
        element: Page(GrowUpBySegment, true),
      },
      {
        path: '/GrowUpByMS',
        element: Page(GrowUpByMS, true),
      },
      {
        path: '/GrowUpByP',
        element: Page(GrowUpByP, true),
      },
      {
        path: '/GrowUpByUP',
        element: Page(GrowUpByUP, true),
      },
      {
        path: '/SHAREINUPBYSegment',
        element: Page(SHAREINUPBYSegment, true),
      },
      {
        path: '/SHAREINUPBYSubChannel',
        element: Page(SHAREINUPBYSubChannel, true),
      },
      {
        path: '/SHAREINUPBYSChannel',
        element: Page(SHAREINUPBYSChannel, true),
      },
      {
        path: '/SHAREINUPBYMS',
        element: Page(SHAREINUPBYMS, true),
      },
      {
        path: '/SHAREINUPBYP',
        element: Page(SHAREINUPBYP, true),
      },
      {
        path: '/SHAREINUPBYUP',
        element: Page(SHAREINUPBYUP, true),
      },
      {
        path: '/Rank',
        element: Page(Rank, true),
      },
      {
        path: '/NpdPage',
        element: Page(NpdPage),
      },
    ],
  },
  {
    path: '/login',
    element: Page(Login),
  },
  {
    path: '/loginForScanCode',
    element: Page(LoginForScanCode),
  },
  {
    path: '/fa-pos-detail',
    element: Page(FctAssumptionsPos, true),
  },
  {
    path: '/LoginForError',
    element: Page(LoginForError),
  },
  {
    path: '*',
    element: Page(NotFound),
  },
];

export default routes;
