import { makeAutoObservable } from 'mobx';
import * as summaryService from '@/services/summary';

export class Summary {
  constructor() {
    makeAutoObservable(this);
  }

  getSoYoySetting(params) {
    return summaryService.getSoYoySetting(params);
  }

  getSoYoyList(params) {
    return summaryService.getSoYoyList(params);
  }

  getSoPosSetting(params) {
    return summaryService.getSoPosSetting(params);
  }

  getSoPosList(params) {
    return summaryService.getSoPosList(params);
  }
  getSoPosListYear(params) {
    return summaryService.getSoPosListYear(params);
  }


  getSoRfcList(params) {
    return summaryService.getSoRfcList(params);
  }

  simpleExportConfirm(params) {
    return summaryService.simpleExportConfirm(params);
  }

  completeExportConfirm(params) {
    return summaryService.completeExportConfirm(params);
  }

  exportStatus(params) {
    return summaryService.exportStatus(params);
  }

  getPosAnalysisSetting(params) {
    return summaryService.getPosAnalysisSetting(params);
  }

  getPosAnalysisList(params) {
    return summaryService.getPosAnalysisList(params);
  }

  getRfcAnalysisList(params) {
    return summaryService.getRfcAnalysisList(params);
  }
  getConditionByView(params) {
    return summaryService.getConditionByView(params);
  }
  getToken(params) {
    return summaryService.getToken(params);
  }
  getsubbrandssettings(params) {
    return summaryService.getsubbrandssettings(params);
  }


}

export const SUMMARY = 'summary';
