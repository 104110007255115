import { createContext, useContext } from 'react';
import { Application, APPLICATION } from './application';
import { Home, HOME } from './home';
import { User, USER } from './user';
import { Dp, DP } from './dp';
import { Fa, FA } from './fa';
import { Ci, CI } from './ci';
import { Fr, FR } from './fr';
import { Sku, SKU } from './sku';
import { Lo, LO } from './lo';
import { ExRfc, EXRFC } from './exRfc';
import { Summary, SUMMARY } from './summary';
import { Common, COMMON } from './common';


function createStores() {
  return {
    [APPLICATION]: new Application(),
    [HOME]: new Home(),
    [USER]: new User(),
    [DP]: new Dp(),
    [COMMON]: new Common(),
    [FA]: new Fa(),
    [CI]: new Ci(),
    [FR]: new Fr(),
    [SKU]: new Sku(),
    [LO]: new Lo(),
    [EXRFC]: new ExRfc(),
    [SUMMARY]: new Summary(),
  };
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export { stores, useStores, StoresContext };
