import Axios from '@/utils/axios';
import Api from '@/constant/api';

// result --left
// 进入页面
export const getResultSetting = async (month_version) => {
  return Axios.jsonGet(`${Api.fr.result.setting}${month_version}/`, { params: { month_version } });
};

// 查询
export const submitResult = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.setting}${params.month_version}/`, params);
};

// 获取详情
export const getResultDetail = async (params) => {
  return Axios.jsonGet(`${Api.fr.result.detail}${params.id}/`, { params });
};

// result --right
// 进入页面
export const getRightSetting = async (result_contrast_id) => {
  return Axios.jsonGet(`${Api.fr.result.rightSetting}${result_contrast_id}/`, { params: { result_contrast_id } });
};

// 查询
export const submitRightResult = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.rightSetting}${params.result_contrast_id}/`, params);
};

// 获取详情
export const getRightDetail = async (params) => {
  return Axios.jsonGet(`${Api.fr.result.rightDetail}${params.id}/`, { params });
};

// 第二个表格修改
export const modifyQuarterly = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.modifyQuarterly}${params.result_id}/`, params);
};
// 第四个表格修改
export const modifyMonthly = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.modifyMonthly}${params.result_id}/`, params);
};
// 第四个表格修改 暂存
export const modifyMonthlyTemp = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.modifyMonthlyTemp}${params.result_id}/`, params);
};

// 获取log options选项
export const getLogParams = async (params) => {
  return Axios.jsonGet(`${Api.fr.result.log}${params.month_version}/`, { params });
};

// 获取log列表
export const getLogs = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.log}${params.month_version}/`, params);
};

// 删除log
export const deleteLog = async (params) => {
  return Axios.jsonDel(`${Api.fr.result.logDelete}${params.id}/`, params);
};

// check
export const check = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.check}${params.month_version}/`, params);
};

// confirm
export const inputConfirm = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.input}${params.month_version}/`, params);
};

// 回退
export const rollback = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.rollback}${params.month_version}/`, params);
};

export const saveResult = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.saveAs}${params.id}/`, params);
};

// 修改别名
export const changeAlias = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.changeAlias}${params.id}/`, params);
};

// 清除修改记录
export const erase = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.erase}${params.result_id}/`, params);
};

export const eraseAdjust = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.eraseAdjust}${params.result_id}/`, params);
};

export const submitLogSave = async (params) => {
  return Axios.jsonPost(`${Api.fr.result.logSave}`, params);
};

// output页面
export const outputDownload = async (params) => {
  return Axios.jsonGet(`${Api.fr.output.download}${params.month_version}/`, { params });
};

export const getOutputSetting = async (params) => {
  return Axios.jsonGet(`${Api.fr.output.setting}${params.month_version}/`, { params });
};

export const submitOutputSetting = async (params) => {
  return Axios.jsonPost(`${Api.fr.output.setting}${params.month_version}/`, params);
};

// 获取 slope 计算部分的月份下拉框选项
export const getSlopeSetting = async (params) => {
  return Axios.jsonGet(`${Api.fr.output.slopeSetting}${params.id}/`, { params });
};

export const submitSlopeSetting = async (params) => {
  return Axios.jsonPost(`${Api.fr.output.slopeSetting}${params.id}/`, params);
};

export const getSlopeDetail = async (params) => {
  return Axios.jsonGet(`${Api.fr.output.slopeDetail}${params.id}/`, params);
};

// 二期 新增页面-POS result by SKU
export const getResultBySkuList = async (params) => {
  return Axios.jsonGet(`${Api.fr.resultBySku.list}`, { params });
};

export const getResultBySkuSetting = async (params) => {
  return Axios.jsonGet(`${Api.fr.resultBySku.setting}`, { params });
};
export const getResultBySkuSaveAs = async (params) => {
  return Axios.jsonPost(`${Api.fr.resultBySku.save_as}`, params);
};
export const getResultBySkuCalc = async (params) => {
  return Axios.jsonPost(`${Api.fr.resultBySku.calc}`, params);
};

export const getResultBySkuCalcStatus = async (params) => {
  return Axios.jsonGet(`${Api.fr.resultBySku.calc_status}`, { params }, true);
};

export const changePercent = async (params) => {
  return Axios.jsonPost(`${Api.fr.resultBySku.change_percent}`, params);
};
export const confirmChange = async (params) => {
  return Axios.jsonPost(`${Api.fr.resultBySku.confirm_change}`, params);
};
export const resetChange = async (params) => {
  return Axios.jsonPost(`${Api.fr.resultBySku.reset_change}`, params);
};
// 三期 新增页面-cbec data split
export const getCbecList = async (params) => {
  return Axios.jsonGet(`${Api.fr.cbec.list}`, { params });
};

export const getCbecSetting = async (params) => {
  return Axios.jsonGet(`${Api.fr.cbec.setting}`, { params });
};

// 点击 底部update按钮 调用
export const cbecUpdate = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.update}`, params);
};

// 修改表格里的值
export const adjustVal = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.adjust}`, params);
};

export const markDataSplit = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.markDataSplit}`, params);
};

export const changeAllPercent = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.changeAllPercent}`, params);
};

export const changeTableData = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.changeTableData}`, params);
};

export const cbecUpdateV2 = async (params) => {
  return Axios.jsonPost(`${Api.fr.cbec.updateV2}`, params);
};

export const logDeleteStepVersion2 = async (params) => {
  return Axios.jsonGet(`${Api.fr.resultBySku.logDeleteStepVersion2}`, { params });
};

export const cbecstep2versionbrand = async (params) => {
  return Axios.jsonGet(`${Api.fr.cbec.cbecstep2versionbrand}`, { params });
};

