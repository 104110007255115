import { makeAutoObservable } from 'mobx';
import * as skuService from '@/services/sku';

export class Sku {
    constructor() {
        makeAutoObservable(this);
    }

    getLogParams(params) {
        return skuService.getLogParams(params);
    }
    getLogs(params) {
        return skuService.getLogs(params);
    }
    deleteLog(params) {
        return skuService.deleteLog(params);
    }
    changeAlias(params) {
        return skuService.changeAlias(params);
    }
    getStep3VersionLogById(params) {
        return skuService.getStep3VersionLogById(params);
    }
    getStep2versionbrand(params) {
        return skuService.getStep2versionbrand(params);
    }

}

export const SKU = 'sku';
