import Axios from '@/utils/axios';
import Api from '@/constant/api';

// pos
// 进入页面
export const getPosSetting = async (month_version) => {
  return Axios.jsonGet(`${Api.fa.pos.setting}${month_version}/`, { month_version });
};

// 修改
export const submitPos = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.setting}${params.month_version}/`, params);
};

// 获取详情
export const getPosDetail = async (params) => {
  return Axios.jsonGet(`${Api.fa.pos.detail}${params.month_version}/${params.assumption_id}/`, { params });
};

// 点击第二个confirm
export const submitAdjustPos = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.adjust}`, params);
};

// 调整POS预测值
export const adjustPos = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.adjustManual}`, params);
};

// 保存一条Assumption记录
export const saveResult = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.saveAs}${params.assumption_id}/`, params);
};

// 获取log options选项
export const getLogParams = async (params) => {
  return Axios.jsonGet(`${Api.fa.pos.log}${params.month_version}/`, { params });
};

// 获取log列表
export const getLogs = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.log}${params.month_version}/`, params);
};

// 删除log
export const deleteLog = async (params) => {
  return Axios.jsonDel(`${Api.fa.pos.logDelete}${params.assumption_id}/`, params);
};

// check
export const check = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.check}${params.month_version}/`, params);
};

// confirm
export const inputConfirm = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.input}${params.month_version}/`, params);
};

// 回退
export const rollback = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.rollback}${params.month_version}/`, params);
};

// 清除Assumption修改记录
export const erase = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.erase}`, params);
};

// 修改别名
export const changeAlias = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.changeAlias}${params.id}/`, params);
};

// 获取 slope 计算部分的月份下拉框选项
export const getSlopeSetting = async (params) => {
  return Axios.jsonGet(`${Api.fa.pos.slopeSetting}${params.month_version}/${params.assumption_id}/`, { params });
};

export const submitSlopeSetting = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.slopeSetting}${params.month_version}/${params.assumption_id}/`, params);
};

// 获取聚合视图log
export const getAggLogList = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.aggSetting}${params.month_version}/`, params);
};

export const submitLogSave = async (params) => {
  return Axios.jsonPost(`${Api.fa.pos.logSave}`, params);
};

// Nielsen
// 进入页面 获取下拉框选项值
export const getNielsenSetting = async (month_version) => {
  return Axios.jsonGet(`${Api.fa.nielsen.setting}${month_version}/`, { month_version });
};

// 查询
export const submitNielsen = async (params) => {
  return Axios.jsonPost(`${Api.fa.nielsen.setting}${params.month_version}/`, params);
};

// 获取详情
export const getNielsenDetail = async (assumption_id) => {
  return Axios.jsonGet(`${Api.fa.nielsen.detail}${assumption_id}/`, {});
};
// 删除log StepVersion1
export const logDeleteStepVersion1 = async (params) => {
  return Axios.jsonGet(`${Api.fa.pos.logDeleteStepVersion1}`, { params });
};

export const getStagebrand = async (params) => {
  return Axios.jsonGet(`${Api.fa.pos.stagebrand}?brandTxt=${params.brandTxt}`);
};