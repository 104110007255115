import Axios from '@/utils/axios';
import Api from '@/constant/api';

// 二期 新增页面-POS result by SKU
export const getForecastBySkuList = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.list}`, { params });
};
export const getForecastBySkuConfirmlist = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.confirmlist}`, { params });
};

export const getForecasttBySkuSetting = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.setting}`, { params });
};

export const saveForecasttBySku = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.saveResult}`, params);
};

export const focExportCommit = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.focExportCommit}`, params);
};
export const exportStatus = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.exportStatus}`, { params }, true);
};
export const focDownload = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.focDownload}`, params);
};

export const focUploadConfirm = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.focUploadConfirm}`, params);
};
export const focUploadConfirmStatus = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.focUploadConfirmStatus}`, { params });
};

export const changeDio = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.changeDio}`, params);
};

export const markFinal = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.markFinal}`, params);
};

export const saveAs = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.saveAs}`, params);
};

export const changeVersionDio = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.changeVersionDio}`, params);
};

export const delVersion = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.delVersion}`, params);
};
export const getVersionInfo = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.getVersionInfo}`, { params });
};
export const confirmFinalVersion = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.confirmFinalVersion}`, params);
};
export const getStep3versionbrand = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.step3versionbrand}`, { params });
};
export const getStep3versionid = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.step3versionid}`, { params });
};
export const getStep4VersionLogByCon = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.getStep4VersionLogByCon}`, params);
};
export const modifyStep4VersionLogById = async (params) => {
  return Axios.jsonPost(`${Api.exRfc.forecastBySku.modifyStep4VersionLogById}`, params);
};
export const getStep4VersionLogById = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.getStep4VersionLogById}`, { params });
};
export const deleteStepVersion4 = async (params) => {
  return Axios.jsonGet(`${Api.exRfc.forecastBySku.deleteStepVersion4}`, { params });
};
// 获取log options选项
export const getLogParams = async (params) => {
  return Axios.jsonGet(`${Api.fr.result.log}${params.month_version}/`, { params });
};