import Axios from '@/utils/axios';
import Api from '@/constant/api';

export const getSoYoySetting = async (params) => {
  return Axios.jsonGet(`${Api.summary.soYoy.setting}`, { params });
};

export const getSoYoyList = async (params) => {
  return Axios.jsonPost(`${Api.summary.soYoy.list}`, params);
};

export const getSoPosSetting = async (params) => {
  return Axios.jsonGet(`${Api.summary.soPos.setting}`, { params });
};

export const getSoPosList = async (params) => {
  return Axios.jsonPost(`${Api.summary.soPos.list}`, params);
};
export const getSoPosListYear = async (params) => {
  return Axios.jsonPost(`${Api.summary.soPos.listyear}`, params);
};

export const getSoRfcList = async (params) => {
  return Axios.jsonPost(`${Api.summary.soRfc.list}`, params);
};

export const simpleExportConfirm = async (params) => {
  return Axios.jsonPost(`${Api.summary.soYoy.simpleExportCommit}`, params);
};

export const completeExportConfirm = async (params) => {
  return Axios.jsonPost(`${Api.summary.soYoy.completeExportCommit}`, params);
};

export const exportStatus = async (params) => {
  return Axios.jsonGet(`${Api.summary.soYoy.exportStatus}`, { params }, true);
};

export const getPosAnalysisSetting = async (params) => {
  return Axios.jsonGet(`${Api.summary.posAnalysis.setting}`, { params });
};

export const getPosAnalysisList = async (params) => {
  return Axios.jsonGet(`${Api.summary.posAnalysis.list}`, { params });
};

export const getRfcAnalysisList = async (params) => {
  return Axios.jsonGet(`${Api.summary.rfcAnalysis.list}`, { params });
};
export const getConditionByView = async (params) => {
  return Axios.jsonGet(`${Api.summary.getConditionByView}`, { params });
};
export const getToken = async (params) => {
  return Axios.jsonGet(`${Api.summary.getToken}`, { params });
};
export const getsubbrandssettings = async (params) => {
  return Axios.jsonGet(`${Api.summary.subbrandssettings}`, { params });
};


